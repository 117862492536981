import React, { useState, useEffect } from 'react';
import { Spinner, Card, Col, Row } from 'react-bootstrap';
import noImagen from '../../../../images/no-image.jpg';
import { LinkPrimary, LinkSecondary } from '../components/CitoPlusLink';
import axiosInstance from '../../../../services/AxiosInstance';
import { useVigilanteReloadContext } from "../../../../context/VisitantesVigilantesContext";
import { notifyError, notifySuccess } from '../components/CitoPlusToastr';
import registro_visitas from '../../../../images/registro_visitante_transparente.png';
import Comentarios from '../components/sidebar_comments';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import _ from 'lodash';

const VisitantesPeatonales = () => {
  const { reloadData, setReloadData } = useVigilanteReloadContext();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const URL = '/api/v3/visitantes/ingreso-peatonal/sin_salida/';
  const URL_NOTAS = '/api/v3/vigilancia/visitantes-comentarios/';
  const [loadingRows, setLoadingRows] = useState({});
  const [filterText, setFilterText] = useState('');
  const [filterDate, setFilterDate] = useState({
    date_after: '',
    date_before: ''
  });
  const [showSidebarComments, setShowSidebarComments] = useState(false);
  const [selectedComentarios, setSelectedComentarios] = useState([]);
  const [selectedPrePostData, setSelectedPrePostData] = useState({});
  const [urlComentariosParams, setUrlComentariosParams] = useState({});

  const fetchUrl = async (loadMore = false) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(URL, {
        params: {
          page: loadMore ? page + 1 : 1,
          search: filterText,
          ...filterDate
        }
      });
      setNextUrl(response.data.next);
      setData(loadMore ? [...data, ...response.data.results] : response.data.results);
      if (loadMore) setPage(prevPage => prevPage + 1);
    } catch (error) {
      notifyError('Error al cargar los datos');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUrl();
  }, []);

  useEffect(() => {
    if (reloadData.visitantes) {
      fetchUrl();
      setReloadData(prev => ({ ...prev, visitantes: false }));
    }
  }, [reloadData.visitantes]);

  const handleSalidaVisitante = (slug) => async () => {
    try {
      setLoadingRows(prev => ({ ...prev, [slug]: true }));
      await axiosInstance.patch(`/api/v3/visitantes/ingreso-peatonal/${slug}/`, { 'ingreso': false });
      fetchUrl();
      notifySuccess('✔️ La salida fue exitosa!');
    } catch (error) {
      notifyError('❌ Hubo un error al procesar la salida del visitante!');
    } finally {
      setLoadingRows(prev => ({ ...prev, [slug]: false }));
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    fetchUrl();
  };

  const handleLoadMore = () => {
    if (nextUrl) {
      fetchUrl(true);
    }
  };

  const handleComentariosClick = (value, slug) => {
    setSelectedPrePostData({
      visitante: slug,
      nota: ''
    });
    setUrlComentariosParams({
      visitante__slug: slug,
      page_size: 100
    });
    setSelectedComentarios(value);
    setShowSidebarComments(true);
  };

  return (
    <>
      <div className='d-flex align-items-center mb-4 flex-wrap'>
        <h4 className='card-title'>Visitantes Peatonales</h4>
      </div>

      <div className="row mb-4">
        <div className="col-xl-12">
          <form className="row" onSubmit={handleSearch}>
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                placeholder="Buscar nombre o identificación..."
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <button
                type="submit"
                className="btn btn-primary w-100">
                <i className='fas fa-search me-2'></i>
                Buscar
              </button>
            </div>
          </form>
        </div>
      </div>

      {loading ? (
        <p>Cargando...</p>
      ) : data.length === 0 ? (
        <Col xl={12} className="text-center">
          <img src={registro_visitas} alt="No hay visitantes pendientes" className="img-fluid" width={"50%"} />
          <h3 className="mt-3">No hay Visitantes</h3>
        </Col>
      ) : (
        <>
          <Row>
            {data.map((item, index) => (
              <Col xl={6} key={index}>
                <Card>
                  <Card.Body>
                    <h4 className='fs-20'>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">Nombre e Identificación </Tooltip>}
                      >
                        <span>
                          {_.startCase(item.nombre)} ({item.identificacion})
                        </span>
                      </OverlayTrigger>
                    </h4>
                    <div className="listline-wrapper mb-4">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">Inmueble</Tooltip>}
                      >
                        <span className="item">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <rect x="0" y="0" width="24" height="24" />
                              <path d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z" fill="#000000" />
                              <rect fill="#FFFFFF" x="13" y="8" width="3" height="3" rx="1" />
                              <path d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z" fill="#000000" opacity="0.3" />
                            </g>
                          </svg>
                          {item.unidad_visita !== null ? (
                            <span>{item.unidad_visita}</span>
                          ) : (
                            "-- Sin Inmueble --"
                          )}
                        </span>
                      </OverlayTrigger>
                      {item.tipo_de_ingreso !== null ? (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id="button-tooltip-2">Tipo de Ingreso</Tooltip>}
                        >
                          <span className="item">
                            <i className="fa fa-person-walking" aria-hidden="true"></i>
                            {_.toUpper(item.tipo_de_ingreso)}
                          </span>
                        </OverlayTrigger>
                      ) : (
                        <></>
                      )}
                    </div>
                    <p className="user d-sm-flex d-block pe-md-3 pe-0">
                      {item.foto && (
                        <a href={item.foto} data-lightbox={`image${index}`}>
                          <img
                            src={item.foto || noImagen}
                            alt=""
                            style={{ objectFit: "cover" }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = noImagen;
                            }}
                          />
                        </a>
                      )}
                      <div className='ms-sm-3 m3-md-5 md-0'>
                        <p>Autorizado: {_.startCase(item.autorizado)}</p>
                        <p>{_.toUpper(item.tipo_de_ingreso)}</p>
                      </div>
                    </p>
                  </Card.Body>
                  <Card.Footer className='d-flex justify-content-between align-items-center flex-wrap'>
                    <div className='mb-2'>
                      <i className='fas fa-clock me-2'></i>
                      {item.h_ingreso}
                    </div>
                    <div className='mb-2'>
                      <LinkSecondary
                        href="#"
                        onClick={() => handleComentariosClick(item.comentarios, item.slug)}
                        title="Comentarios"
                      >
                        <i className="fa-solid fa-comments me-2"></i>
                        ({item.total_comentarios})
                      </LinkSecondary>
                      <LinkPrimary
                        href="#"
                        className={`position-relative ${loadingRows[item.slug] ? 'disabled' : ''}`}
                        onClick={handleSalidaVisitante(item.slug)}
                      >
                        {loadingRows[item.slug] ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          <>
                            <i className="fas fa-person-walking me-2"></i>
                            Salida
                          </>
                        )}
                      </LinkPrimary>
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
          {nextUrl && (
            <button className="btn load-more-btn w-100 mt-3" onClick={handleLoadMore}>
              <i className="fas fa-arrow-down me-2"></i> Cargar más...
            </button>
          )}
          <Comentarios
            showSidebarComments={showSidebarComments}
            setShowSidebarComments={setShowSidebarComments}
            urlComentarios={URL_NOTAS}
            urlComentariosParams={urlComentariosParams}
            selectedPrePostData={selectedPrePostData}
          />
        </>
      )}
    </>
  );
}

export default VisitantesPeatonales;
