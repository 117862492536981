import Index from './jsx/index';
import RouterVigilantes from './jsx/router_vigilantes';
import React, { lazy, Suspense, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated, getTipoUsuario, getUserId } from './store/selectors/AuthSelectors';
// import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { CustomToastContainer } from './jsx/components/CitoPlus/components/CitoPlusToastr';
import LogRocket from 'logrocket';
import ReactGA from 'react-ga4';
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('G-LWJ8WBF1SJ');
  LogRocket.init('yozrd2/citoplus-v2');
}

const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => import('./jsx/pages/Login'));

const withRouter = (Component) => (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  return <Component {...props} router={{ location, navigate, params }} />;
};

const CommonSuspense = ({ children }) => (
  <Suspense fallback={
    <div id="preloader">
      <div className="sk-three-bounce">
        <div className="sk-child sk-bounce1"></div>
        <div className="sk-child sk-bounce2"></div>
        <div className="sk-child sk-bounce3"></div>
      </div>
    </div>
  }>
    {children}
  </Suspense>
);

const App = ({ isAuthenticated, tipoUsuario, userId, userEmail, userUsername }) => {

  if (isAuthenticated) {
    ReactGA.set({userId: userId});
    ReactGA.set({user_id: userId});
    LogRocket.identify(userUsername)

    LogRocket.identify(userId, {
      name: userUsername,
      email: userEmail,
    });
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    checkAutoLogin(dispatch, navigate);
  }, []);

  const routeBlog = (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/page-register' element={<SignUp />} />
      <Route path='/page-forgot-password' element={<ForgotPassword />} />
    </Routes>
  );

  return (
    <div className={`vh-100 ${isAuthenticated ? 'authenticated' : ''}`}>
      <CommonSuspense>
        {isAuthenticated ? (
          tipoUsuario === "VIGILANTE" ? (
            <RouterVigilantes />
          ) : (
            <Index />
          )
        ) : (
          routeBlog
        )}
      </CommonSuspense>
      <CustomToastContainer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
  tipoUsuario: getTipoUsuario(state),
  userId: getUserId(state),
  userEmail: state.auth.auth.user.email,
  userUsername: state.auth.auth.user.username
});

const ConnectedApp = connect(mapStateToProps)(withRouter(App));

export default ConnectedApp;
