import React, { useEffect, useState, useRef } from "react";
import { Modal, Nav, Tab } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import ReactWebcam from "react-webcam";
import Loading from "../../components/loading";
import { BotonPrimaryText, BotonDangerText, BotonInfoText } from "../../components/BotonesCustom";
import { FieldBasic, SelectVivienda, SelectParqueaderos } from "../../components/FieldBasic";
import CustomSelect from "../../components/Select";
import axiosInstance from "services/AxiosInstance";
import { toast } from "react-toastify";
import { LectorDeBarras, validacionPlaca } from "../../utils";

import { useVigilanteReloadContext } from "../../../../../context/VisitantesVigilantesContext";
import FacturaParking from "../../Facturacion/FacturaPark";

import imagen200300 from '../../../../../images/200x300.png';
import _ from "lodash";

const formGruposSchema = Yup.object().shape({
  identificacion: Yup.string()
    .required("Por favor, ingresa la identificación.")
    .matches(/^[0-9]+$/, 'La identificación debe ser un número')
    .min(8, "La identificación debe tener 8 dígitos")
    .max(15, "La identificación debe tener 15 dígitos")
    .nullable(true),
  nombre: Yup.string()
    .required("Por favor, ingresa el nombre y los apellidos.")
    .nullable(true),
  autorizado_por: Yup.string()
    .required("Por favor, ingresa el nombre de quien autoriza.")
    .nullable(true),
  tarifa: Yup.string()
    .required("Por favor, ingresa la tarifa")
    .nullable(true),
  placa: Yup.string()
    .matches(/^[A-Za-z0-9]{5,6}$/, 'El formato de la placa esta errado')
    .required("Por favor, ingresa la placa")
    .nullable(true)
});

const IngresoVehicular = ({ showModal, onClose }) => {
  const initialValuesEmpty = {
    identificacion: '',
    nombre: '',
    inmueble: null,
    autorizado_por: '',
    tipo_de_ingreso: { value: '0', label: 'VISITANTE' },
    foto: null,
    foto2: null,
    ingreso: true,
    tarifa: null,
    placa: null,
    no_parqueadero: null,
  };

  const URL = "/api/v3/visitantes/ingreso-vehicular/";

  const { setReloadData } = useVigilanteReloadContext();

  const [initialValues, setInitialValues] = useState(initialValuesEmpty);
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [capturedPhoto1, setCapturedPhoto1] = useState(null);
  const [capturedPhoto2, setCapturedPhoto2] = useState(null);
  const [cameraOpen1, setCameraOpen1] = useState(false);
  const [cameraOpen2, setCameraOpen2] = useState(false);
  const webcamRef1 = useRef(null);
  const webcamRef2 = useRef(null);
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);

  // modal recibido
  const [showModalRecibo, setShowModalRecibo] = useState(false);
  const [showModalReciboUrl, setShowModalReciboUrl] = useState(null);
  const [showModalReciboData, setShowModalReciboData] = useState({});

  let submitAction = null;

  const tipoVisitanteOptions = [
    { slug: "0", nombre: "VISITANTE" },
    { slug: "1", nombre: "DOMICILIO" },
    { slug: "2", nombre: "CONTRATISTA" },
    { slug: "3", nombre: "CLIENTE" },
    { slug: "4", nombre: "PROVEEDOR" },
    { slug: "5", nombre: "FAMILIAR" },
    { slug: "6", nombre: "ADMINISTRATIVO" },
  ];

  const [tarifasOptions, setTarifasOptions] = useState([]);

  useEffect(() => {
    if (!showModal) {
      setCapturedPhoto1(null);
      setCapturedPhoto2(null);
      setCameraOpen1(false);
      setCameraOpen2(false);
    }
  }, [showModal]);


  // Tarifas

  const getTarifas = async () => {
    try {
      const response = await axiosInstance.get("/api/v3/visitantes/ingreso-vehicular/tarifas/");
      setTarifasOptions(response.data);
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  useEffect(() => {
    getTarifas();
  }, [showModal]);

  // FIN Tarifas

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {

    let _tipo_de_ingreso;

    if (typeof values.tipo_de_ingreso === 'string') {
      _tipo_de_ingreso = values.tipo_de_ingreso;
    } else if (values.tipo_de_ingreso instanceof Object) {
      _tipo_de_ingreso = values.tipo_de_ingreso.value;
    }

    const postData = {
      ...values,
      unidad_visita: values.inmueble,
      autorizado: values.autorizado_por,
      tipo_de_ingreso: _tipo_de_ingreso,
    }
    try {
      setLoadingSubmit(false);
      const response = await axiosInstance.post(URL, postData);
      setInitialValues(initialValuesEmpty);
      onClose();
      toast.success("✔️ El ingreso fue exitoso !", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // Recibo
      setShowModalReciboUrl(
        "/api/v3/visitantes/ingreso-vehicular/" + response.data.slug + "/detalle_factura_en_progreso/"
      );
      setShowModalReciboData(response.data.detalle_factura);
      setShowModalRecibo(true);

      resetForm();
      setReloadData(prev => ({ ...prev, visitantes: true }));
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setSubmitting(false);
      setLoadingSubmit(false);
    }
  };

  const handleSaveAndAddAnother = async (values, { setFieldValue, setSubmitting, resetForm }) => {
    const postData = {
      ...values,
      unidad_visita: values.inmueble,
      autorizado: values.autorizado_por,
      tipo_de_ingreso: values.tipo_de_ingreso.value,
    }

    try {
      setLoadingSubmit(false);
      const response = await axiosInstance.post(URL, postData);
      setInitialValues(initialValuesEmpty);
      toast.success("✔️ El ingreso fue exitoso !", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setCapturedPhoto1(null);
      setCapturedPhoto2(null);
      setCameraOpen1(false);
      setCameraOpen2(false);
      resetForm();
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setSubmitting(false);
      setLoadingSubmit(false);
    }
  };

  const handleClearForm = async (values, { setFieldValue, resetForm }) => {
    resetForm();
    setFieldValue('tipo_de_ingreso', { value: '0', label: 'VISITANTE' });
    setInitialValues(initialValues);
    setCapturedPhoto1(null);
    setCapturedPhoto2(null);
    setCameraOpen1(false);
    setCameraOpen2(false);
  };

  const capturePhoto1 = (setFieldValue) => {
    if (webcamRef1.current) {
      const imageSrc = webcamRef1.current.getScreenshot();
      setCapturedPhoto1(imageSrc);
      setFieldValue('foto', imageSrc);  // Actualizar el campo foto en Formik
      setCameraOpen1(false);
    }
  };

  const capturePhoto2 = (setFieldValue) => {
    if (webcamRef2.current) {
      const imageSrc = webcamRef2.current.getScreenshot();
      setCapturedPhoto2(imageSrc);
      setFieldValue('foto2', imageSrc);  // Actualizar el campo foto2 en Formik
      setCameraOpen2(false);
    }
  };

  const handleFileUpload1 = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCapturedPhoto1(reader.result);
        setFieldValue('foto', reader.result);  // Actualizar el campo foto en Formik
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileUpload2 = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCapturedPhoto2(reader.result);
        setFieldValue('foto2', reader.result);  // Actualizar el campo foto2 en Formik
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadClick1 = () => {
    fileInputRef1.current.click();
  };

  const handleUploadClick2 = () => {
    fileInputRef2.current.click();
  };

  function handleErrorResponse(error) {
    let errorMessages = [];

    if (error.response && error.response.data) {
      const errorData = error.response.data;

      if (errorData.type === "validation_error" && Array.isArray(errorData.errors)) {
        errorData.errors.forEach(err => {
          const fieldMessage = `${err.attr}: ${err.detail}`;
          errorMessages.push(fieldMessage);
        });
      } else if (errorData.message) {
        errorMessages.push(errorData.message);
      } else {
        errorMessages.push("An unexpected error occurred.");
      }
    } else {
      errorMessages.push(error.message || "An unexpected error occurred.");
    }

    const errorMessage = errorMessages.join("\n");

    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
      <Modal show={showModal} size="lg">
        {loadingForm ? (
          <Loading />
        ) : (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={formGruposSchema}
            onSubmit={(values, { setFieldValue, setSubmitting, isSubmitting, resetForm }) => {
              if (submitAction === "handleSubmit") {
                handleSubmit(values, { setFieldValue, setSubmitting, resetForm });
              } else if (submitAction === "handleSaveAndAddAnother") {
                handleSaveAndAddAnother(values, { setFieldValue, setSubmitting, resetForm });
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              resetForm,
              setSubmitting
            }) => (
              <div role="document">
                <form onSubmit={handleSubmit}>
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                      Ingreso Visitante Vehicular
                    </h5>
                  </div>

                  <div className="modal-body">
                    <div className="row justify-content-center">
                      <div className="container col">
                        <div className="row">
                          <div className="custom-tab-1">
                            <Tab.Container defaultActiveKey={"foto"}>
                              <Nav as="ul" className="nav-tabs">
                                <Nav.Item as="li" key={"foto"}>
                                  <Nav.Link eventKey={"foto"}>
                                    <i className={`la la-image me-2`} />
                                    Foto 1
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li" key={"foto2"}>
                                  <Nav.Link eventKey={"foto2"}>
                                    <i className={`la la-image me-2`} />
                                    Foto 2
                                  </Nav.Link>
                                </Nav.Item>
                              </Nav>
                              <Tab.Content className="pt-4">
                                <Tab.Pane eventKey={"foto"} key={"foto"}>
                                  {/* Foto 1 */}
                                  <div className="row">
                                    <div className="col">
                                      <div className="row">
                                        <BotonPrimaryText
                                          type="button"
                                          onClick={() => setCameraOpen1(!cameraOpen1)}
                                        >
                                          {cameraOpen1 ? "Cerrar Cámara 1" : "Tomar Foto 1"}
                                        </BotonPrimaryText>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="row">
                                        {cameraOpen1 ? (
                                          <BotonInfoText
                                            type="button"
                                            onClick={() => capturePhoto1(setFieldValue)}
                                          >
                                            Capturar Foto 1
                                          </BotonInfoText>
                                        ) : (
                                          <>
                                            <input
                                              type="file"
                                              className="form-file-input form-control"
                                              accept="image/*"
                                              ref={fileInputRef1}
                                              onChange={(event) => handleFileUpload1(event, setFieldValue)}
                                              style={{ display: 'none' }}
                                            />
                                            <BotonPrimaryText
                                              type="button"
                                              onClick={handleUploadClick1}
                                            >
                                              Subir Foto 1
                                            </BotonPrimaryText>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col">
                                    <div className="p-2">
                                      <div style={{ height: 300, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {cameraOpen1 ? (
                                          <ReactWebcam
                                            audio={false}
                                            ref={webcamRef1}
                                            screenshotFormat="image/jpeg"
                                            width={200}
                                            height={300}
                                            minScreenshotHeight={1024}
                                            minScreenshotWidth={1536}
                                            style={{
                                              objectFit: 'cover',
                                            }}
                                            videoConstraints={{
                                              width: 1024,
                                              height: 1536,
                                              facingMode: "user"
                                            }}
                                          />
                                        ) : (
                                          <div style={{ height: '100%' }}>
                                            {capturedPhoto1 ? (
                                              <img
                                                src={capturedPhoto1}
                                                className="img-fluid"
                                                alt="Captured"
                                                style={{
                                                  width: '200px',
                                                  height: '300px',
                                                  objectFit: 'cover',
                                                }}
                                              />
                                            ) : (
                                              <img
                                                src={imagen200300}
                                                className="img-fluid"
                                                alt="Placeholder"
                                                style={{
                                                  objectFit: 'cover',
                                                }}
                                              />
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey={"foto2"} key={"foto2"}>
                                  {/* FOTO 2 */}
                                  <div className="row">
                                    <div className="col">
                                      <div className="row">
                                        <BotonPrimaryText
                                          type="button"
                                          onClick={() => setCameraOpen2(!cameraOpen2)}
                                        >
                                          {cameraOpen2 ? "Cerrar Cámara 2" : "Tomar Foto 2"}
                                        </BotonPrimaryText>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="row">
                                        {cameraOpen2 ? (
                                          <BotonInfoText
                                            type="button"
                                            onClick={() => capturePhoto2(setFieldValue)}
                                          >
                                            Capturar Foto 2
                                          </BotonInfoText>
                                        ) : (
                                          <>
                                            <input
                                              type="file"
                                              className="form-file-input form-control"
                                              accept="image/*"
                                              ref={fileInputRef2}
                                              onChange={(event) => handleFileUpload2(event, setFieldValue)}
                                              style={{ display: 'none' }}
                                            />
                                            <BotonPrimaryText
                                              type="button"
                                              onClick={handleUploadClick2}
                                            >
                                              Subir Foto 2
                                            </BotonPrimaryText>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="p-2">
                                      <div style={{ height: 300, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {cameraOpen2 ? (
                                          <ReactWebcam
                                            audio={false}
                                            ref={webcamRef2}
                                            screenshotFormat="image/jpeg"
                                            width={200}
                                            height={300}
                                            minScreenshotHeight={1024}
                                            minScreenshotWidth={1536}
                                            style={{
                                              objectFit: 'cover',
                                            }}
                                            videoConstraints={{
                                              width: 1024,
                                              height: 1536,
                                              facingMode: "user"
                                            }}
                                          />
                                        ) : (
                                          <div style={{ height: '100%' }}>
                                            {capturedPhoto2 ? (
                                              <img
                                                src={capturedPhoto2}
                                                className="img-fluid"
                                                alt="Captured"
                                                style={{
                                                  width: '200px',
                                                  height: '300px',
                                                  objectFit: 'cover',
                                                }}
                                              />
                                            ) : (
                                              <img
                                                src={imagen200300}
                                                className="img-fluid"
                                                alt="Placeholder"
                                                style={{
                                                  objectFit: 'cover',
                                                }}
                                              />
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </Tab.Container>
                          </div>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="p-2">
                          <div className="form-group pb-2">
                            <FieldBasic
                              name="identificacion"
                              required
                              errors={errors.identificacion}
                            >
                              <Field
                                type="text"
                                name="identificacion"
                                id="identificacion"
                                autoComplete="off"
                                className="form-control"
                                placeholder="Identificación"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.identificacion}
                                onKeyDown={(e) => LectorDeBarras(e, { setFieldValue })}
                              />
                            </FieldBasic>
                          </div>
                          <div className="form-group pb-2">
                            <FieldBasic
                              name="nombre"
                              required
                              errors={errors.nombre}
                            >
                              <Field
                                type="text"
                                name="nombre"
                                id="nombre"
                                autoComplete="off"
                                className="form-control"
                                placeholder="Nombre y apellidos"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.nombre}
                              />
                            </FieldBasic>
                          </div>
                          <div className="form-group pb-2">
                            <FieldBasic
                              name="autorizado_por"
                              required
                              errors={errors.autorizado_por}
                            >
                              <Field
                                type="text"
                                name="autorizado_por"
                                id="autorizado_por"
                                autoComplete="off"
                                className="form-control"
                                placeholder="Autorizado por"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.autorizado_por}
                              />
                            </FieldBasic>
                          </div>
                          <div className="form-group pb-2">
                            <CustomSelect
                              id="tipo_de_ingreso"
                              name="tipo_de_ingreso"
                              options={tipoVisitanteOptions}
                              initSelection={{ value: 'VISITANTE', label: 'VISITANTE' }}
                              defaultValue={values.tipo_de_ingreso}
                              onChange={(selectedOption) => {
                                setFieldValue('tipo_de_ingreso', selectedOption ? selectedOption.value : null);
                              }}
                            />
                          </div>
                          <div className="form-group pb-2">
                            <SelectVivienda
                              id="inmueble"
                              name="inmueble"
                              isSearchable="true"
                              isClearable="true"
                              errors={errors.inmueble}
                              onChange={(selectedOptions) => {
                                setFieldValue('inmueble', selectedOptions ? selectedOptions.label : null);
                              }}
                            />
                          </div><div className="form-group pb-2">
                            <div className="row">
                              <div className="col-md-6 col-sm-12">
                                <FieldBasic
                                  name="tarifa"
                                  required
                                  errors={errors.tarifa}
                                >
                                  <CustomSelect
                                    id="tarifa"
                                    name="tarifa"
                                    options={tarifasOptions}
                                    placeholder="Tarifas..."
                                    defaultValues={values.tarifa}
                                    errors={errors.tarifa}
                                    onChange={(selectedOption) => {
                                      setFieldValue('tarifa', selectedOption ? selectedOption.value : null);
                                    }}
                                  />
                                </FieldBasic>
                              </div>
                              <div className="col-md-6 col-sm-12">
                                <FieldBasic
                                  name="placa"
                                  errors={errors.placa}
                                >
                                  <Field
                                    type="text"
                                    name="placa"
                                    id="placa"
                                    autoComplete="off"
                                    className="form-control"
                                    placeholder="placa"
                                    //  onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.placa}
                                    onChange={(e) => validacionPlaca(e, { setFieldValue })}
                                    maxlength="6"
                                  />
                                </FieldBasic>
                              </div>
                            </div>
                          </div>
                          <div className="form-group pb-2">
                            <SelectParqueaderos
                              id="no_parqueadero"
                              name="no_parqueadero"
                              isSearchable="true"
                              isClearable="true"
                              errors={errors.no_parqueadero}
                              onChange={(selectedOption) => {
                                setFieldValue('no_parqueadero', selectedOption ? selectedOption.label : null);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer">
                    {loadingSubmit ? (
                      <div className="text-center">
                        <Loading />
                      </div>
                    ) : (
                      <>
                        <BotonDangerText
                          type="button"
                          onClick={onClose}
                        >
                          Cancelar
                        </BotonDangerText>
                        <BotonDangerText
                          type="button"
                          onClick={() => handleClearForm(values, { setFieldValue, setSubmitting, resetForm })}
                          disabled={isSubmitting}
                        >
                          Limpiar
                        </BotonDangerText>
                        <BotonPrimaryText
                          type="submit"
                          disabled={isSubmitting}
                          onClick={() => {
                            submitAction = "handleSubmit";
                          }}
                        >
                          Guardar
                        </BotonPrimaryText>
                        <BotonPrimaryText
                          type="submit"
                          disabled={isSubmitting}
                          onClick={() => {
                            submitAction = "handleSaveAndAddAnother";
                          }}
                        >
                          Guardar y añadir otro
                        </BotonPrimaryText>
                      </>
                    )}
                  </div>
                </form>
              </div>
            )}
          </Formik>
        )}
      </Modal>
      <FacturaParking
        isOpen={showModalRecibo}
        onClose={() => setShowModalRecibo(false)}
        URL={showModalReciboUrl}
        dataFromPatch={showModalReciboData}
      />
    </>
  );
};

export default IngresoVehicular;
